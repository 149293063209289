import React, { useState } from "react";
import { BiSolidSun, BiSolidMoon } from "react-icons/bi";
import { HiMenuAlt3, HiMenuAlt1, HiMail } from "react-icons/hi";
import ResponsiveMenu from "./ResponsiveMenu";
import logo from '../../Assets/blbg.png'
import limo from "../../Assets/limo.png";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaLocationArrow,
  FaMobileAlt,
} from "react-icons/fa";
import { FaX } from "react-icons/fa6";
import { RiMailFill, RiPhoneFill, RiWhatsappFill } from "react-icons/ri";
import { MdCall } from "react-icons/md";




export const Navlinks = [
  {
    id: 1,
    name: "VEHICLES",
    link: "/#ca",
  },
  {
    id: 2,
    name: "ABOUT US",
    link: "/#cars",
  },
  {
    id: 3,
    name: "SERVICES",
    link: "/#about",
  },
  {
    id: 4,
    name: "LUXURY FLEET",
    link: "/#ca",
  },
  {
    id: 4,
    name: "REVIEW",
    link: "/#boo",
  },
  {
    id: 4,
    name: "SPECIALS",
    link: "https://customer.moovs.app/marina-global-transportation",
  },
  {
    id: 4,
    name: "PACKAGES",
    link: "https://customer.moovs.app/marina-global-transportation",
  },
  {
    id: 5,
    name: "BOOKING",
    link: "https://customer.moovs.app/marina-global-transportation",
  },
];
const Navbar = ({ theme, setTheme }) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div
      className="relative z-10 shadow-md w-full dark:bg-black dark:text-white duration-300
    "
    >
     
            
      <div className="container md:py-0">
        <div className="flex justify-between items-center">
          {/* <div>
            <span className="text-3xl font-bold font-serif">Blessed Limo</span>
          </div> */}
          <Link to={"/"}
               onClick={() => {
                window.scrollTo(0, 0);
              }}     
            >
                <img src={logo} alt="" className='w-32 h-12 lg:w-40 lg:h-16 mb-3' />
            </Link>
          <nav className="hidden md:block">
            <ul className="flex items-center gap-6">
              {Navlinks.map(({ id, name, link }) => (
                <li key={id} className="py-4"
                >
                  <a
                    href={link}
                    className=" text-sm font-semibold font-Ubuntu hover:text-primary py-2 hover:border-b-2 hover:border-primary transition-colors duration-500  "
                  >
                    {name}  +
                  </a>
                </li>
              ))}
              {/* DarkMode feature implement */}
            
              {theme === "dark" ? (
                <BiSolidSun
                  onClick={() => setTheme("light")}
                  className="text-2xl cursor-pointer"
                />
              ) : (
                <BiSolidMoon
                  onClick={() => setTheme("dark")}
                  className="text-2xl cursor-pointer"
                />
              )}
                <a href="mailto:info@blessedlimos.com" >
              <HiMail
                  // onClick={() => setTheme("dark")}
                  className="text-2xl cursor-pointer"
                  />
                  </a>

                  <a href="tel:800-556-1849" >
                <MdCall
                  // onClick={() => setTheme("dark")}
                  className="text-2xl cursor-pointer"
                />
                </a>
            </ul>
          </nav>
          {/* Mobile view  */}
          <div className="flex items-center gap-4 md:hidden ">
            {/* dark  mode */}
            <a href="mailto:info@blessedlimos.com" >
              <HiMail
                  // onClick={() => setTheme("dark")}
                  className="text-2xl cursor-pointer"
                  />
                  </a>

                  <a href="tel:800-556-1849" >
                <MdCall
                  // onClick={() => setTheme("dark")}
                  className="text-2xl cursor-pointer"
                />
                </a>
            {theme === "dark" ? (
              <BiSolidSun
                onClick={() => setTheme("light")}
                className="text-2xl"
              />
            ) : (
              <BiSolidMoon
                onClick={() => setTheme("dark")}
                className="text-2xl"
              />
            )}
            {/* Mobile Hamburger icon */}
            {showMenu ? (
              <HiMenuAlt1
                onClick={toggleMenu}
                className=" cursor-pointer transition-all"
                size={30}
              />
            ) : (
              <HiMenuAlt3
                onClick={toggleMenu}
                className="cursor-pointer transition-all"
                size={30}
              />
            )}
          </div>
        </div>
      </div>
      <ResponsiveMenu showMenu={showMenu} setShowMenu={setShowMenu} />
    </div>
  );
};

export default Navbar;
