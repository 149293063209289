import React from 'react';

const TermsAndPrivacy = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
      <h1>Marina Global Transportation LLC – Terms & Privacy for SMS Communications</h1>
      <p><strong>Effective Date:</strong> October 11, 2024</p>

      <h2>Introduction</h2>
      <p>
        Marina Global Transportation LLC, in partnership with Blessed Global Transportation, is committed to protecting your privacy 
        and ensuring compliance with SMS communication regulations. This Terms & Privacy document explains how we collect, use, 
        and protect your personal information when you opt-in to receive SMS communications from us, as well as how you can 
        opt-out at any time.
      </p>

      <h2>1. SMS Communication Opt-In Process</h2>
      <p>
        By providing your phone number and opting in to receive SMS communications, you agree to receive text messages from 
        Marina Global Transportation LLC. These messages may include, but are not limited to:
      </p>
      <ul>
        <li>Reservation confirmations and updates</li>
        <li>Pricing quotes and invoices</li>
        <li>Promotional offers and discounts</li>
        <li>Important service announcements</li>
      </ul>
      <p>To opt-in to our SMS service, you can:</p>
      <ul>
        <li>Check the opt-in box during the booking process on our website or verbally consent during phone interactions with our team.</li>
        <li>Text “JOIN” to our designated toll-free number (800) 556-1849 to subscribe to SMS communications.</li>
      </ul>
      <p>
        Once opted in, you consent to receiving up to 5 messages per month related to your bookings, promotional offers, or service updates.
      </p>

      <h2>2. SMS Communication Opt-Out Process</h2>
      <p>You may opt-out of SMS communications at any time by:</p>
      <ul>
        <li>Replying “STOP” to any text message you receive from Marina Global Transportation LLC.</li>
        <li>Contacting our customer service team at (800) 556-1849 or via email at <a href="mailto:info@blessedlimos.com">info@blessedlimos.com</a> to be removed from our SMS list.</li>
      </ul>
      <p>
        Once you opt-out, you will no longer receive SMS messages from us unless you opt back in by re-subscribing through the methods mentioned above.
      </p>

      <h2>3. Data Collection and Use</h2>
      <p>
        When you provide us with your mobile number, we collect and use your information solely for the purpose of delivering SMS 
        communications related to our services. We do not share, sell, or distribute your phone number to third parties, except as 
        required by law or necessary to facilitate SMS delivery through our trusted service provider.
      </p>
      <p>We may collect the following information:</p>
      <ul>
        <li>Mobile phone number</li>
        <li>Booking and reservation details</li>
        <li>Information provided during service interactions</li>
      </ul>
      <p>This information will only be used for SMS communication purposes.</p>

      <h2>4. Data Security</h2>
      <p>
        We use industry-standard security measures to ensure your personal information, including your phone number, is protected. 
        Our SMS provider complies with all necessary legal and privacy obligations to ensure the security and confidentiality of 
        your data. All SMS communications are handled in accordance with applicable federal and state regulations, including the 
        Telephone Consumer Protection Act (TCPA).
      </p>

      <h2>5. Contact Us</h2>
      <p>If you have any questions or concerns regarding our SMS communications, opt-in/out processes, or privacy practices, please contact us:</p>
      <ul>
        <li>Phone: (800) 556-1849</li>
        <li>Email: <a href="mailto:info@blessedlimos.com">info@blessedlimos.com</a></li>
        <li>Address: Marina Global Transportation LLC, 1249 Jennie Ln SW, Lilburn, GA 30047</li>
      </ul>

      <h2>6. Changes to Terms & Privacy</h2>
      <p>
        Marina Global Transportation LLC reserves the right to update or modify this Terms & Privacy policy at any time. Any changes 
        will be posted on our website, and your continued use of our services constitutes acceptance of these changes.
      </p>
    </div>
  );
};

export default TermsAndPrivacy;
