import React from "react";

const testimonialData = [
  {
    name: "Gerald Joseph",
    image: "https://lh3.googleusercontent.com/a-/ALV-UjUQHUklTATaDSye_1LKmoIJvuijaq7A0GCcuZfAvlH-SsDqJYwm=w54-h54-p-rp-mo-ba4-br100",
    description: "Great prices easy booking and driver was prompt and courteous would definitely use again",
    aosDelay: "0",
  },
  {
    name: "Mbaintu Barlay",
    image: "https://lh3.googleusercontent.com/a-/ALV-UjUYahQT2Fib8V24mVccIB8elHIW0YbzGbtKtCbPQnE3hzZ72GWkfQ=w54-h54-p-rp-mo-br100",
    description: "Very professional! I enjoyed their high class service and would definitely recommend them for any and all transportation services, from weddings to just city to city rides. They are awesome!!!",
    aosDelay: "300",
  },
  {
    name: "Nicholas Ehenemba",
    image: "https://lh3.googleusercontent.com/a-/ALV-UjUvxYaFuFeGGiSLY7-WHFAE-vNgg_rBLQshm-uols-9knR26m8=w54-h54-p-rp-mo-br100",
    description: "Setting up my pickups was a breeze,the drivers were on time and am thrilled with their service..so accommodating and flexible....will surely use when next in town.",
    aosDelay: "1000",
  },
];
const Testimonial = () => {
  return (
    <>
      <span id="boo"></span>
      <div className="dark:bg-black dark:text-white py-14 sm:pb-24">
        <div className="container">
          {/* Header */}
          <div className="space-y-4 pb-12">
            <p
              data-aos="fade-up"
              className="text-3xl font-bold text-center sm:text-4xl font-Ubuntu"
            >
              What Our Clients Say About Us
            </p>
            <p data-aos="fade-up" className="text-center sm:px-44">
              <div className="grid place-items-center ">
            <img
                    src="https://www.limoserviceatlanta.com/wp-content/uploads/2023/11/google-rating.webp"
                    alt=""
                    className="rounded-full h-20"
                  />
                  </div>
              Based on 500+ Reviews
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 text-black dark:text-white">
            {testimonialData.map((skill) => (
              <div
                key={skill.name}
                data-aos="fade-up"
                data-aos-delay={skill.aosDelay}
                className="card text-center group space-y-3 sm:space-y-6 p-4 sm:py-12 dark:bg-white/20 bg-gray-100 duration-300  rounded-lg "
              >
                <div className="flex justify-center place-items-center ">
                  <img
                    src="https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-google-sva-scholarship-20.png"
                    alt=""
                    className=" h-6"
                  />
                  <img
                    src={skill.image}
                    // src="https://picsum.photos/200"
                    alt=""
                    className="rounded-full w-10 h-10 ml-5"
                  />
                </div>
                <div className="text-sm">⭐⭐⭐⭐⭐</div>
                <p>{skill.description}</p>
                <p className="text-center font-semibold">{skill.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
